@import '~compass-mixins/lib/compass/css3';

/* Variables */
$color-1: #141922;
$color-2: white;
$color-3: linear-gradient(265.1deg, #8369F4 34.76%, #F039E2 87.26%);
;

/* Fonts */
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,700);
body {
  font-family: 'Open Sans', 'Poppins', Helvetica, Arial, sans-serif;
  font-size: 1em;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.05em;
}

/* Layout */
* {
  @include box-sizing(border-box);
}

/* Styling */
.timeline {
  margin: 4em auto;
  position: relative;
  max-width: 46em;
  
  &:before {
    background-color: $color-1;
    content: '';
    margin-left: -1px;
    position: absolute;
    top: 0;
    left: 2em;
    width: 2px;
    height: 100%;
  }
}

.timeline-event {
  position: relative;
  
  &:hover {
  
    .timeline-event-icon {
      @include rotate (-45deg);
      background: linear-gradient(265.1deg, #8369F4 34.76%, #F039E2 87.26%);
    }
    
    .timeline-event-thumbnail {
      @include box-shadow(inset 40em 0 0 0 $color-3);
      background: linear-gradient(265.1deg, #8369F4 34.76%, #F039E2 87.26%);
    }
  }
}

.timeline-event-copy {
  padding: 2em;
  position: relative;
  top: -1.875em;
  left: 4em;
  width: 80%;
  
  h3 {
    font-size: 1.75em;
  }
  
  h4 {
    font-size: 1.2em;
    margin-bottom: 1.2em;
  }
  
  strong {
    font-weight: 700;
  }
  
  p:not(.timeline-event-thumbnail) {
    padding-bottom: 1.2em;
  }
}

.timeline-event-icon {
  @include transition(transform 0.2s ease-in);
  @include rotate (45deg);
  
  background-color: $color-1;
//   outline: 10px solid $color-2;
  display: block;
  margin: 0.5em 0.5em 0.5em -0.5em;
  position: absolute;
  top: -5px;
  left: 30.5px;
  width: 20px;
  height: 20px;
}

.timeline-event-thumbnail {
  @include transition(box-shadow 0.5s ease-in 0.1s);
  color: $color-2;
  font-size: 0.75em;
  
  background-color: $color-1;
  @include box-shadow(inset 0 0 0 0em #ef795a);
  display: inline-block;
  margin-bottom: 1.2em;
  padding: 0.25em 1em 0.2em 1em;
}